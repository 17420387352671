import React from 'react';
import styled from 'styled-components';
import { observer } from "mobx-react-lite";
import { useMediaQuery } from 'react-responsive'
// @ts-ignore
import PlayIcon from '../../resources/icons/playbutton.svg';
import { CardPlayButton } from '../CardPlayButton';
import { breakpoints, colors, fonts } from '../../styles/variables'
import './me.css';

const COLORS = Object.values(colors.radiochannels)

const BACKDROP_IMAGES = {
  0: '/images/seloytyi.jpg'
}

const CardContainer = styled.div`
  width: 200px;
  max-width: 200px;
  height: 235px;
  color: ${colors.black};
  margin: 10px 10px 0px 0px;
  background-repeat: no-repeat;
  background-size: 250px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 145px;
    min-width: 145px;
    height: 170px;
    background-size: 150px;
  }
`

const CardPictureContainer = styled.div.attrs((props: { id: number }) => props)`
  display: grid;
  grid-template-rows: auto auto auto;
  justify-items: center;
  background: ${props => (COLORS[props.id])};
  width: 100%;
  height: 235px;
  margin: 10px 10px 0px 0px;
  padding-top: 20px;
  background-image: url("${props => (BACKDROP_IMAGES[props.id])}");
  background-size: contain;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    grid-template-rows: auto 60px auto;
    justify-content: center;
    text-align: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    height: 145px;
    padding-top: 5px;
  }
`

const Logo = styled.img`
  height: 50px;
  width: auto;
  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: auto;
    height: 30px;
  }
`

const Title = styled.div`
  font-family: ${fonts.montserrat};
  color: ${colors.white};
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 19px;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    font-size: 10px;
    line-height: 12px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const ChannleTitle = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 250px;
  height: 22px;
  margin-top: 10px;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    font-size: 11px;
    line-height: 13px;
    -webkit-line-clamp: 2;
    width: 125px;
    height: 25px;
  }
`


// @ts-ignore
interface Props {
  id: number;
  title: string;
  mediaSrc: string;
  mediaIndex: number;
  radioChannel?: boolean;
  radioChannelName?: string;
  radioChannelUrl?: string;
  radioChannelForcedPicture?: string;
}

const RadioChannelMediaElement: React.FC<Props> = observer(props => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.xl });
  const ImageSrc = props.radioChannelForcedPicture ? '' : (isMobile ? "/images/dei_plus_logo.png" : "/images/dei_plus_logo_slogan.png");
  const mainChannelTitle = 'Radio Dei';

  return (
    <CardContainer >
      <CardPictureContainer id={props.mediaIndex} >
        <Title>{props.title.includes('pääkanava') ? mainChannelTitle : (props.title.includes('Missioradio') ? '-' : props.title)}</Title>
        <CardPlayButton
          mediaSrc={(decodeURIComponent(props.mediaSrc).includes('http') ?
            props.mediaSrc : 'https://jako.dei.fi/ondemand/lowbit/' + props.mediaSrc)
          }
          showName=''
          title={props.title}
          mediaId={props.id}
          live={props.radioChannel}
        />
        <Logo src={ImageSrc} />
      </CardPictureContainer>
      <TextContainer>
        <ChannleTitle>{props.title}</ChannleTitle>
      </TextContainer>
    </CardContainer >
  )
});

export { RadioChannelMediaElement };
